import React from "react";

import { Heading } from "../../reusable/atoms";

import { DefaultLayout, Section } from "../../reusable/Templates";
import { Banner } from "../../reusable/organisms";

import contactBanner from "../../assets/images/beta/beta.png";
import * as styles from "./finance.module.scss";

const Finance = () => {
  return (
    <DefaultLayout>
      <Banner image={contactBanner} title="National Program for the Prevention and Control of Beta
Thalassemia and Other Hemoglobinopathies in India" />
      <Section>
        <div className="container p-0">
          <div className="row">
            <div className="content-beta">
              <Heading level={2} className={styles.heading}>Curriculum Overview</Heading>
              <p>This curriculum covers various aspects of genetics and hematology, featuring
                lectures by esteemed resource persons from renowned institutions. Each module
                includes detailed sections on specific topics</p>
            </div>
          </div>
        </div>
        {/* <Heading className="no-margin margin-b-lg">Beta Thalassemia</Heading> */}
        <section className="curriculum">
        </section>
        <Heading className="no-margin margin-b-sm" size="h4">
          Beta Thalassemia Modules
        </Heading>
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/apprs3eDmn4tsmvLY/shrqZkD2wPwTKluSy?viewControls=on"
          frameBorder="0"
          onMouseWheel=""
          width="100%"
          height="533"
          style={{ background: 'transparent', border: '1px solid #ccc' }}
        ></iframe>
        <br />
        <br />
        <Heading className="margin-b-sm" size="h4">
          Session Recordings
          <iframe
            className="airtable-embed"
            src="https://airtable.com/embed/apprs3eDmn4tsmvLY/shreumvaXL2Rm0N1N?layout=card"
            frameBorder="0"
            onMouseWheel=""
            width="100%"
            height="533"
            style={{ background: 'transparent', border: '1px solid #ccc' }}
          ></iframe>
        </Heading>
      </Section>
    </DefaultLayout>
  );
};

export default Finance;
